import React, { useState } from "react"

import { Stack } from "@kiwicom/orbit-components"

import {
  DetailsInner,
  DetailsWrapper,
} from "components/generic/multi-column-layout/styled-components"
import { DriversAdminLayout } from "components/layout-custom"
import {
  TimeOffBookingFilters,
  TimeOffBookingQuery,
  TimeOffBookingSection,
} from "components/people/leave/time-off-booking-section"

import { StateStoredInUrl, arrayField } from "utils/url-state"

const urlState = new StateStoredInUrl<TimeOffBookingQuery>(
  [
    arrayField("hubs", Number),
    arrayField("contractTypes", String),
    arrayField("leaveTypes", String),
  ],
  () => ({})
)

const Page = () => {
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [filter, setFilter] = urlState.useState({})

  return (
    <DriversAdminLayout
      title="Leave"
      padded={false}
      navRightContent={
        <TimeOffBookingFilters filter={filter} setFilter={setFilter} />
      }
    >
      <DetailsWrapper fullScreen={false}>
        <DetailsInner fullScreen={false}>
          <Stack spacing="large">
            <TimeOffBookingSection
              title="Pending Requests"
              audience="admin"
              showRequestedOnly
              showAllTime
              refreshCounter={refreshCounter}
              refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
              filter={filter}
            />
            <TimeOffBookingSection
              title="All"
              audience="admin"
              showAddButton
              refreshCounter={refreshCounter}
              refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
              filter={filter}
            />
          </Stack>
        </DetailsInner>
      </DetailsWrapper>
    </DriversAdminLayout>
  )
}

export default Page
